.ct_postage_main {
    background-color: #fff;
    padding: 30px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    border-top: 5px solid #bf8d1e;
}
.ct_postage_type_box table.table tr td {
    padding: 8px 10px;
    font-size: 15px;
}
.ct_postage_type_box {
    background-color: #eee;
    border-radius: 5px;
    padding: 10px;
}
.ct_postage_inner_cnt li{
    font-size: 15px;
    position: relative;
    list-style: none;
}
.ct_postage_inner_cnt li:before {
    content: "*";
    position: absolute;
    left: -25px;
    color: #bf8d1e;
    font-size: 25px;
    top: -5px;
}
.highlight {
	border: 2px solid #ff0000;
	/* background-color: #ffe6e6; */
  }

@media screen and (max-width:767px){
    .ct_postage_main{
        padding-inline: 15px;
    }
}