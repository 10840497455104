.cssload-loader {
	width: 250px;
	height: 49px;
	line-height: 49px;
	text-align: center;
	position: absolute;
	left: 50%;
	transform: translate(-50%, -50%);
		-o-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
	font-family: helvetica, arial, sans-serif;
	text-transform: uppercase;
	font-weight: 900;
	font-size:18px;
	color: #be891d;
	/* letter-spacing: 0.2em; */
    font-family: 'Blacksword';
    background-image: linear-gradient(#e9c746, #b47e11);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.cssload-loader::before, .cssload-loader::after {
	content: "";
	display: block;
	width: 15px;
	height: 15px;
	background: #be891d;
	position: absolute;
	animation: cssload-load 0.81s infinite alternate ease-in-out;
		-o-animation: cssload-load 0.81s infinite alternate ease-in-out;
		-ms-animation: cssload-load 0.81s infinite alternate ease-in-out;
		-webkit-animation: cssload-load 0.81s infinite alternate ease-in-out;
		-moz-animation: cssload-load 0.81s infinite alternate ease-in-out;
}
.cssload-loader::before {
	top: 0;
}
.cssload-loader::after {
	bottom: 0;
}


span.ct_quantity_text {
	position: absolute !important;
	top: 50% !important;
	transform: translateY(-50%) !important;
  }
  span.ct_quantity_text.ct_quantity_minus{
	left: 10px !important;
  }
  span.ct_quantity_text.ct_quantity_plus{
	right: 10px !important;
  }
  .ct_quantity_text i {
	  font-size: 10px !important;
  }

@keyframes cssload-load {
	0% {
		left: 0;
		height: 29px;
		width: 15px;
	}
	50% {
		height: 8px;
		width: 39px;
	}
	100% {
		left: 229px;
		height: 29px;
		width: 15px;
	}
}

@-o-keyframes cssload-load {
	0% {
		left: 0;
		height: 29px;
		width: 15px;
	}
	50% {
		height: 8px;
		width: 39px;
	}
	100% {
		left: 229px;
		height: 29px;
		width: 15px;
	}
}

@-ms-keyframes cssload-load {
	0% {
		left: 0;
		height: 29px;
		width: 15px;
	}
	50% {
		height: 8px;
		width: 39px;
	}
	100% {
		left: 229px;
		height: 29px;
		width: 15px;
	}
}

@-webkit-keyframes cssload-load {
	0% {
		left: 0;
		height: 29px;
		width: 15px;
	}
	50% {
		height: 8px;
		width: 39px;
	}
	100% {
		left: 229px;
		height: 29px;
		width: 15px;
	}
}

@-moz-keyframes cssload-load {
	0% {
		left: 0;
		height: 29px;
		width: 15px;
	}
	50% {
		height: 8px;
		width: 39px;
	}
	100% {
		left: 229px;
		height: 29px;
		width: 15px;
	}
}


.ct_cart_update_calender .rdrDateDisplayWrapper {
    width: 95%;
}

.ct_country_drop_list{
	background-color: #fff;
}


span.ct_single_item_buy_rent_tag {
    content: "";
    position: absolute;
    top: 5px;
    left: 0;
    background-color: #000;
    padding: 5px 30px;
    color: #fff;
    border-radius: 2px;
} 

.ct_multiselect_input_20 {
    height: 55px;
    vertical-align: middle;
    display: grid;
    width: 100%;
    flex: 1;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
}

.ct_multiselect_input_20 span.css-1u9des2-indicatorSeparator {
    display: none;
}
.css-t3ipsp-control:hover,.css-t3ipsp-control:focus, .css-13cymwt-control:focus{
	border-color: #000 !important;
}

.css-t3ipsp-control{
	border-color: #000 !important;
	box-shadow: 0 0 0 1px #000 !important;
}
.ct_border_radius_5{
	border-radius: 5px;
}

.ct_shop_cart_tagline_bg{
	background-color: #bb851b4a;
    padding: 15px;
    border-radius: 10px;
	margin-bottom: 20px;
}

.MuiBox-root.css-1q7gveb {
    width: 100%;
    overflow-y: auto;
	overflow-x: hidden;
    height: auto;
    max-height: 700px;
	padding-inline: 15px !important;
}

.ct_img_crop .ant-upload.ant-upload-select {
    background-color: #f5f5f5 !important;
}

.ct_img_crop img {
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
}
.ct_img_crop img ~ div {
    top: 50% !important;
    left: 50% !important;
    display: flex !important;
    justify-content: center;
    transform: translate(-50%, -50%);
}

.ct_upload_multiple_clr{
	border: 2px solid #e4e4e4;
    padding: 10px 15px;
    border-radius: 2px;
}

.ct_from_to_calnder{
	border: 1px solid #eee;
}

div#ct_warning_modal.show {
    opacity: 1;
	position: fixed;
	z-index: 9999;
    display: block;
}
div#ct_warning_modal.show:before{
	content: "";
	position: fixed;
	inset: 0px;
	background-color: #000;
	opacity: 0.5;
	z-index: -1;
}
.ct_warning_close {
    position: absolute;
    top: 20px;
    right: 28px;
    border-radius: 5px;
    width: 30px;
    padding: 0px !important;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    color: #ffff;
    background-image: unset;
}
#ct_warning_modal .modal-content {
    padding-top: 50px;
    padding-bottom: 30px;
}
.ct_btn_yellow123{
	background-color: #b47e11;
	border-color: #b47e11;
}
.ct_fs_25{
	font-size: 25px;
}

.shop-checkout .checkout-steps__item:hover{
	color: #000;
}

